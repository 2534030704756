import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { appointmentMessage } from '../helpers/flexMessage';
import * as Api from '../apis';
import * as liff from '../apis/liff';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Layout from '../components/Layout';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Header from '../components/Header';
import Calendar from 'react-calendar';
import withAuth from '../hooks/withAuth';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: '100px',
  },
  textField: {
    width: '60%',
    marginBottom: 25,
    background: 'white',
  },
  img: {
    maxWidth: '100%',
  },
  nextButton: {
    width: 150,
  },
  container: {
    maxWidth: 984,
    padding: 20,
    width: '100%',
  },
  description: {
    color: 'black',
  },
  remark: {
    whiteSpace: 'pre-wrap',
    color: 'black',
    background: '#dbe7f3',
    padding: 10,
    borderRadius: 4,
  },
}));

const Appointment = ({ location }) => {
  const classes = useStyles();
  const [consultant, setConsultant] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [slotTimeId, setSlotTimeId] = useState('None');
  const [dateTimeSlots, setDateTimeSlots] = useState({});
  const [dateList, setDateList] = useState([]);
  const [remark, setRemark] = useState('');
  const [step, setStep] = useState(0);
  const user_question_id = useSelector(
    state => state.questionnaire.userQuestionnaireId,
  );

  useEffect(() => {
    const consultant = location.state.consultant;
    setConsultant(consultant);
    fetchTimeSlot(consultant.id);
  }, []);

  function fetchTimeSlot(consultantId) {
    let dateSlots = {};
    Api.getTimeSlots(consultantId).then(data => {
      const timeSlots = data.results;
      timeSlots.forEach(timeSlot => {
        const slot = dateSlots[timeSlot.date] || [];
        slot.push(timeSlot);
        dateSlots[timeSlot.date] = slot;
      });
      setDateTimeSlots(dateSlots);
      setDateList(getAvailableDate(dateSlots));
    });
  }

  function confirmAppointment() {
    if (!selectedDate || slotTimeId === 'None') {
      alert('กรุณากรอกข้อมูลให้ครบ');
      return;
    }
    const data = {
      remark,
      consultant_id: consultant.id,
      time_slot_id: slotTimeId,
      user_question_id,
    };
    Api.postAppointment(data)
      .then(data => {
        if (liff.isInClient()) {
          liff
            .sendMessages([
              {
                type: 'flex',
                altText: 'ส่งคำขอการนัดหมายดหมายเรียบร้อย',
                contents: appointmentMessage(data.data),
              },
            ])
            .then(data => {
              liff.closeWindow();
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          const result = data.data;
          alert(
            `นัดหมายสำเร็จ\nนัดหมายวันที่: ${result.appointment_datetime}\nผู้ให้ตำปรึกษา: ${result.consultant}`,
          );
          navigate('/');
        }
      })
      .catch(e => {
        fetchTimeSlot(consultant.id);
        alert(e);
      });
  }

  function onDateChange(date) {
    const dateFormat = formatDate(date);
    setSelectedDate(dateFormat);
    setSlotTimeId('None');
  }

  function getTileDisabled({ date, view }) {
    if (view === 'month') {
      if (!isAvailable(date)) {
        return true;
      }
    }
  }

  function getTitleClassName({ date, view }) {
    if (view === 'month') {
      if (isAvailable(date)) {
        return 'available';
      }
    }
    return '';
  }

  function getTileContent({ date, view }) {
    if (view === 'month') {
      if (isAvailable(date)) {
        return <p style={{ margin: 0 }}>(ว่าง)</p>;
      }
    }
  }

  function isAvailable(date) {
    const now = formatDate(new Date());
    const dateFormat = formatDate(date);
    if (dateList.includes(dateFormat) && dateFormat >= now) {
      return true;
    }
    return false;
  }

  function formatDate(date) {
    if (!date) return '';
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }

  function getAvailableDate(dateSlots) {
    const dateList = [];
    for (let date in dateSlots) {
      dateList.push(date);
    }
    return dateList;
  }

  if (!consultant) {
    return false;
  }

  const slotTimeList = dateTimeSlots[selectedDate] || [];

  return (
    <Layout>
      {step === 0 && (
        <>
          <Header
            backAction={() => window.history.back()}
            text={consultant.display_name}
          />
          <Box justifyContent='center' display='flex'>
            <Box
              className={classes.container}
              flexDirection='column'
              justifyContent='center'
              display='flex'
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <img src={consultant.image} className={classes.img} />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 20 }}>
                  <h3 style={{ margin: 0 }}>ความเชี่ยวชาญ</h3>
                  <p className={classes.description}>
                    {consultant.description}
                  </p>
                </Grid>
              </Grid>
              <h3 style={{ marginTop: 15, marginBottom: 0 }}>รายละเอียด</h3>
              <p className={classes.remark}>{consultant.remark}</p>
              <Box
                flexDirection='column'
                justifyContent='center'
                display='flex'
                alignItems='center'
              >
                <Button
                  onClick={() => setStep(1)}
                  color='primary'
                  variant='contained'
                  className={classes.nextButton}
                >
                  จองคิว
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {step === 1 && (
        <>
          <Header
            backAction={() => setStep(0)}
            text={`นัดหมาย ${consultant.display_name}`}
          />
          <Box
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            display='flex'
          >
            <Box p={1}>
              <h3>กรุณาระบุวัน เวลา นัด</h3>
            </Box>
            <Box
              justifyContent='center'
              alignItems='center'
              display='flex'
              mb={4}
            >
              <Calendar
                onChange={onDateChange}
                locale='th-th'
                tileDisabled={getTileDisabled}
                tileClassName={getTitleClassName}
                tileContent={getTileContent}
              />
            </Box>
            <Select
              style={{ textAlign: 'center' }}
              className={classes.textField}
              variant='outlined'
              value={slotTimeId}
              onChange={e => setSlotTimeId(e.target.value)}
            >
              <MenuItem value='None'>เลือกเวลา</MenuItem>
              {slotTimeList.map(slotTime => (
                <MenuItem key={slotTime.id} value={slotTime.id}>
                  {slotTime.start_time}
                </MenuItem>
              ))}
            </Select>
            <TextField
              variant='outlined'
              className={classes.textField}
              placeholder='หมายเหตุ'
              multiline
              rows={3}
              value={remark}
              onChange={e => setRemark(e.target.value)}
            />
            <Button
              onClick={confirmAppointment}
              color='primary'
              variant='contained'
            >
              แจ้งความจำนง
            </Button>
          </Box>
        </>
      )}
      <br />
    </Layout>
  );
};

export default withAuth(Appointment);
